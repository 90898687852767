<template>
  <a-modal
    :title="$t('menu.print_waybill_batch_import')"
    width="auto"
    v-model:visible="state.visible"
    :centered="true"
    :closable="false"
    :mask-closable="false"
  >
    <template #footer>
      <a-button type="ghost" @click="handleConfirm">{{ $t('common.close') }}</a-button>
    </template>
    <a-table
      :columns="columns"
      :data-source="state.result?.items"
      :scroll="{ x: 500, y: 600 }"
      size="small"
      :row-key="(record) => record.id"
      :pagination="state.pagination"
      @change="(page) => state.pagination.current = page.current"
    >
      <template #title>
        <span>{{ $t("common.the_number_of_successes_is_p0", [result.succeedCount]) }}, </span>
        <span>{{ $t("common.the_number_of_fail_is_p0", [result.failCount]) }}</span>
      </template>
      <template #status="{ record }">
        <template v-if="record.errCode || record.msg">
          <span>{{ $t('common.exception') }}: </span>
          <span v-if="record.errCode">【{{ record.errCode }}】</span>
          <span>{{ record.msg || '-' }}</span>
        </template>
        <span class="text-success">{{ $t('common.succeed') }}</span>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, watch } from 'vue';
import { Button, Modal, Table } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  emits: ['confirm', 'update:visible'],
  components: {
    AModal: Modal,
    AButton: Button,
    ATable: Table,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Object,
      default: () => ({})
    },
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      result: {
        succeedCount: 0,
        failCount: 0,
        items: [],
      },
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });

    const columns = [
      {
        dataIndex: "planName",
        width: 200,
        title: () => vueI18n.t('logistics.transport_plan_name'),
      },
      {
        width: 300,
        title: () => vueI18n.t('warehouse.status'),
        slots: {
          customRender: "status"
        }
      },
    ]

    watch(() => [props.visible, props.result], ([visible, result]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
      }
      if (typeof result === 'object') {
        state.result = result;
      }
    }, { immediate: true, deep: true });

    const handleConfirm = () => {
      emit('confirm');
      close();
    }

    const close = () => {
      emit('update:visible', false);
    }

    return {
      state,
      columns,
      handleConfirm,
      close,
    }
  }
})
</script>

<style scoped>

</style>
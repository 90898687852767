<template>
  <Content>
    <template #contentTitle>{{ $t("logistics.transport_plan") }} - {{ $t('menu.transport_import') }}</template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-button type="primary" :loading="downloadLoading" ghost @click="handleDownload()">{{ $t('common.download_template_file') }}</a-button>
        </a-col>
        <a-col>
          <a-upload
            name="template"
            list-type="text"
            accept=".xlsx"
            :show-upload-list="false"
            :customRequest="handleUpload"
          >
            <a-button type="primary" :loading="tableData.loading" ghost>{{ $t('common.upload_file') }}</a-button>
          </a-upload>
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: true, y: wrap.contentHeight - 170 }"
        :pagination="tableData.pagination"
        size="small"
        :loading="tableData.loading"
        :row-key="(record) => record.rowKey"
        @change="(page) => tableData.pagination.current = page.current"
      >
        <template #transportRouteTitle>
          <div>{{ $t('logistics.transport_route') }}</div>
          <div>{{ $t('logistics.destination_type') }}</div>
        </template>
        <template #serialNumber="{ index }">
          {{ ((tableData.pagination.current - 1) * tableData.pagination.pageSize) + (index + 1) }}
        </template>
        <template #errObjsTitle>
          {{ $t('common.exception') }}
          <CTooltip :title="$t('logistics.only_the_correct_data_will_be_imported')" />
        </template>
        <template #planName="{ record }">
          {{ record.planName || '-' }}
        </template>
        <template #transportRoutes="{ record }">
          <template v-if="record.parseFromAddress && record.parseToAddress">
            <span v-if="record.parseFromAddress.countryId === record.parseToAddress.countryId">
              {{ $t($enumLangkey("transportRoutes", transportRoutesEnum.mainland)) }}
            </span>
            <span v-else>{{ $t($enumLangkey("transportRoutes", transportRoutesEnum.international)) }}</span>
          </template>
          <span v-else>-</span>
        </template>
        <template #destinationType="{ record }">
          <span v-if="record.destinationType">{{ $t($enumLangkey("destinationType", record.destinationType)) }}</span>
          <span v-else>-</span>
        </template>
        <template #parseFromAddress="{ record }">
          <span v-if="record.parseFromAddress">
            {{ getLanguageName(record.parseFromAddress.countryCnName, record.parseFromAddress.countryEnName) }} /
            {{ getLanguageName(record.parseFromAddress.cityCnName, record.parseFromAddress.cityEnName) }}
          </span>
          <span v-else>-</span>
        </template>
        <template #parseToAddress="{ record }">
          <span v-if="record.parseToAddress">
            {{ getLanguageName(record.parseToAddress.countryCnName, record.parseToAddress.countryEnName) }} /
            {{ getLanguageName(record.parseToAddress.cityCnName, record.parseToAddress.cityEnName) }}
          </span>
          <span v-else>-</span>
        </template>
        <template #transportCount="{ record }">
          {{ record.transportCount || '0' }}
        </template>
        <template #transportBoxCount="{ record }">
          {{ record.transportBoxCount || '0' }}
        </template>
        <template #errObjs="{ record }">
          <template v-if="record.errObjs.length">
            <div v-for="(errObj, index) in record.errObjs" :key="index">
              <span v-if="index < 2">
                <a-row>
                  <a-col>
                      {{index+1}}.
                    </a-col>
                    <a-col>
                      {{$t(getErrorMsgKey(errObj.errCode))}}
                      <span v-if="errObj?.fields && errObj?.fields?.length>0">
                        (
                          <span v-for="(field,i) in errObj?.fields"
                                :key="i">
                            {{field}}
                            <span v-if="i!=(errObj?.fields?.length-1)">,</span>
                          </span>
                        ) 
                      </span>
                      <div v-if="errObj.msg">
                        <small class="text-grey">{{errObj.msg}}</small>
                      </div>
                  </a-col>
                </a-row>

              </span>
            </div>
            <a-popover v-if="record.errObjs.length > 2" :title="$t('warehouse.data_error')" placement="left">
              <template #content>
                <div v-for="(errObj, index) in record.errObjs" :key="index" class="mb-2" style="min-width: 300px;">
                   <a-row>
                      <a-col>
                          {{index+1}}.
                        </a-col>
                        <a-col>
                          {{$t(getErrorMsgKey(errObj.errCode))}}
                          <span v-if="errObj?.fields && errObj?.fields?.length>0">
                            (
                              <span v-for="(field,i) in errObj?.fields"
                                    :key="i">
                                {{field}}
                                <span v-if="i!=(errObj?.fields?.length-1)">,</span>
                              </span>
                            ) 
                          </span>
                          <div v-if="errObj.msg">
                            <small class="text-grey">{{errObj.msg}}</small>
                          </div>
                      </a-col>
                    </a-row>
                </div>
              </template>
              <span class="text-primary" style="cursor: pointer;">{{ $t('public.more') }}</span>
            </a-popover>
          </template>
          <span v-else>-</span>
        </template>
        <template #operation="{ index }">
          <a-button type="ghost" danger @click="handleRemoveItem(index)">{{ $t('common.remove') }}</a-button>
        </template>
      </a-table>
      <ImportTransportResultModal v-model:visible="importResultState.visible" :result="importResultState.result" @confirm="handleBack" />
    </template>
    <template #contentFooter>
      <a-row :gutter="[16, 16]" type="flex" justify="end">
        <a-col>
          <a-button type="ghost" @click="handleBack">{{ $t('common.backtrack') }}</a-button>
        </a-col>
        <a-col>
          <a-button type="primary" :loading="confirmLoading" :disabled="getNormalItems === 0" @click="handleConfirm">{{ $t('common.confirm') }}</a-button>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { Button, Col, Row, Table, Upload, Modal, Popover } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CTooltip from '@/views/components/CTooltip.vue';
import ImportTransportResultModal from './components/ImportTransportResultModal.vue';
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
import { useTab } from "@/hooks/tabs/index";
import { downloadFile } from "@/utils/downloader";
import { getName, randomString } from '@/utils/general.js';
import {
  generateTransportPlanTemplate,
  parseTransportPlanExcelData,
  importTransportPlanData,
} from '@/api/modules/transportation/index.js';
import { transportRoutes as transportRoutesEnum } from '@/enum/enum.json';
import { getErrorMsgKey } from '@/api/errorHandlers';

export default defineComponent({
  name: "transport_import",
  components: {
    ARow: Row,
    ACol: Col,
    AUpload: Upload,
    AButton: Button,
    ATable: Table,
    APopover: Popover,
    Content,
    CTooltip,
    ImportTransportResultModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { getters } = useStore();
    const { delVisitedRoute } = useTab();

    const state = reactive({
      downloadLoading: false,
      confirmLoading: false,
      tableData: {
        loading: false,
        tableList: [],
        pagination: {
          current: 1,
          pageSize: 10,
        }
      },
      importResultState: {
        visible: false,
        result: null,
      }
    });

    const columns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        width: 80,
        slots: {
          customRender: "serialNumber",
        }
      },
      {
        title: () => vueI18n.t("logistics.transport_plan_name"),
        width: 150,
        slots: {
          customRender: "planName",
        }
      },
      {
        title: () => vueI18n.t("logistics.transport_route"),
        width: 150,
        slots: {
          customRender: "transportRoutes",
        }
      },
      {
        title: () => vueI18n.t("logistics.destination_type"),
        width: 150,
        slots: {
          customRender: "destinationType",
        }
      },
      {
        title: () => vueI18n.t("logistics.start_country_city"),
        width: 150,
        slots: {
          customRender: "parseFromAddress",
        }
      },
      {
        title: () => vueI18n.t("logistics.destination_country_city"),
        width: 150,
        slots: {
          customRender: "parseToAddress",
        }
      },
      {
        title: () => vueI18n.t("logistics.transport_count"),
        width: 120,
        slots: {
          customRender: "transportCount",
        }
      },
      {
        title: () => vueI18n.t("logistics.transport_box_number"),
        width: 120,
        slots: {
          customRender: "transportBoxCount",
        }
      },
      {
        width: 200,
        slots: {
          title: "errObjsTitle",
          customRender: "errObjs",
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("common.operation"),
        slots: {
          customRender: "operation",
        }
      },
    ]

    // 获取可正常上传的运输计划数量
    const getNormalItems = computed(() => {
      return state.tableData.tableList.filter(item => item.errObjs.length === 0).length;
    })

    const getLanguageName = (cnName, enName) => {
      return getName({ cnName, enName }, getters.language);
    }

    const handleRemoveItem = (index) => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_remove"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          state.tableData.tableList.splice(index, 1);
        },
      });
    }

    // 下载模板文件
    const handleDownload = () => {
      state.downloadLoading = true;
      let url = generateTransportPlanTemplate();
      downloadFile(url, "Import Transport Plan template file.xlsx");
      state.downloadLoading = false;
    }

    // 上传文件
    const handleUpload = ({ file }) => {
      state.tableData.loading = true;
      let parameter = new FormData();
      parameter.append("file", file);
      parseTransportPlanExcelData(parameter).then(({ result }) => {
        if (Array.isArray(result)) {
          state.tableData.tableList = result.map(item => {
            item.rowKey = randomString();
            return item;
          });
        }
      }).catch((error) => {}).finally(() => {
        state.tableData.loading = false;
      });
    }

    const confirmRequest = () => {
      state.confirmLoading = true;
      const list = state.tableData.tableList.filter(item => item.errObjs.length === 0);
      importTransportPlanData(list).then(({ result }) => {
        state.importResultState.result = result;
        state.importResultState.visible = true;
      }).catch(() => {}).finally(() => {
        state.confirmLoading = false;
      });
    }

    // 确认
    const handleConfirm = () => {
      let hasAbnormalItems = state.tableData.tableList.filter(item => item.errObjs.length);
      if (hasAbnormalItems.length) {
        Modal.confirm({
          title: vueI18n.t("common.hint"),
          content: vueI18n.t("logistics.exceptions_will_not_be_uploaded_whether_to_submit"),
          okText: vueI18n.t("common.confirm"),
          cancelText: vueI18n.t("common.cancel"),
          onOk: () => {
            confirmRequest();
          },
        });
      } else {
        confirmRequest();
      }
    }

    const handleBack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "transport_plan" });
    }

    return {
      ...toRefs(state),
      transportRoutesEnum,
      columns,
      getNormalItems,
      getLanguageName,
      handleRemoveItem,
      handleDownload,
      handleUpload,
      handleConfirm,
      handleBack,
      getErrorMsgKey
    }
  }
})
</script>

<style scoped>

</style>